import { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import pfpBuyContactsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_buy_contacts_button_click';
import { Button } from '@hh.ru/magritte-ui';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import { ResumeCardActionsContext } from 'src/components/ResumeCard/Actions/Context';
import useEmployerBillingInfo from 'src/components/SupernovaMainMenu/EmployerProfile/hooks/useEmployerBillingInfo';
import translation from 'src/components/translation';
import { usePfpInfo } from 'src/hooks/usePfpInfo';
import { useSelector } from 'src/hooks/useSelector';
import { BoughtTopicsError } from 'src/models/boughtTopics';
import { MicroFrontends, updateMicroFrontends } from 'src/models/microFrontends';
import { ResumeCardData } from 'src/models/resumeCard';
import { MIDDLE_DOT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import fetcher from 'src/utils/fetcher';

import Error from 'src/components/PfpBillingButton/Error';
import { FailedTopic, SucceedTopic } from 'src/components/PfpBillingButton/types';

const TrlKeys = {
    text: 'pfpBillingButton.text',
};

const BUY_PFP_CONTACTS_URL = '/shards/buy_pfp_contacts';
const GET_RESUME_URL = '/shards/resume';

const NoErrorFailedTopicCodes: BoughtTopicsError[] = ['CONTACTS_ALREADY_VISIBLE', 'CONTACTS_ALREADY_BOUGHT'];

declare global {
    interface FetcherPostApi {
        [BUY_PFP_CONTACTS_URL]: {
            queryParams: void;
            body: {
                resumeId: string;
                topicId?: string;
            };
            response: Record<string, SucceedTopic | FailedTopic>;
        };
    }

    interface FetcherGetApi {
        [GET_RESUME_URL]: {
            queryParams: {
                resumeId: string;
                resumeHash: string;
                topicId?: string;
                vacancyId?: string;
                getWebcallMicrofrontend?: boolean;
            };
            response: {
                resume: ResumeCardData;
                microFrontends: MicroFrontends;
            };
        };
    }
}

interface PfpBillingButtonProps {
    onContactsBought?: (resume: ResumeCardData) => void;
    resumeHash: string;
    topicId?: string;
    resumeId: string;
    type?: 'button' | 'link' | 'resumeViewButton';
    isMobile?: boolean;
}

const PfpBillingButton: TranslatedComponent<PfpBillingButtonProps> = ({
    trls,
    onContactsBought,
    resumeId,
    resumeHash,
    topicId,
    type = 'button',
    isMobile = false,
}) => {
    const dispatch = useDispatch();
    const { fetchEmployerBillingInfo } = useEmployerBillingInfo();
    const [isLoading, setIsLoading] = useState(false);
    const { vacancyId } = useSelector((state) => state.router.location.query);
    const [error, setError] = useState<BoughtTopicsError | null>(null);
    const { pfpDetails: { currency, relevantActionPrice: amount } = {} } = usePfpInfo();
    const { isNew, markAsRead } = useContext(ResumeCardActionsContext);

    const onCloseError = useCallback(() => {
        setError(() => null);
    }, []);

    const handleBuyPfpContacts = useCallback(async () => {
        if (isLoading) {
            return;
        }
        pfpBuyContactsButtonClick({ resumeId, topicId, vacancyId });
        try {
            setIsLoading(true);
            isNew && markAsRead?.();
            const body: FetcherPostApi[typeof BUY_PFP_CONTACTS_URL]['body'] = {
                resumeId,
                ...(topicId && { topicId }),
            };
            const result = await fetcher.post(BUY_PFP_CONTACTS_URL, body);
            const failedTopic = Object.values(result.data).find(
                (topicInfo) =>
                    topicInfo.status === 'FAIL' && !NoErrorFailedTopicCodes.includes(topicInfo.errorDetails.code)
            );
            if (!failedTopic) {
                const { resume, microFrontends } = await fetcher.get(GET_RESUME_URL, {
                    params: {
                        resumeId,
                        resumeHash,
                        topicId,
                        vacancyId,
                        getWebcallMicrofrontend: true,
                    },
                });
                if (microFrontends) {
                    dispatch(updateMicroFrontends(microFrontends));
                }
                void fetchEmployerBillingInfo();
                onContactsBought?.(resume);
            } else if (failedTopic.status === 'FAIL') {
                setError(failedTopic.errorDetails.code);
            } else {
                setError('UNHANDLED_ERROR');
            }
        } catch (_) {
            setError('UNHANDLED_ERROR');
        } finally {
            setIsLoading(false);
        }
    }, [
        isLoading,
        resumeId,
        topicId,
        vacancyId,
        isNew,
        markAsRead,
        resumeHash,
        fetchEmployerBillingInfo,
        onContactsBought,
        dispatch,
    ]);

    const text = (
        <>
            {trls[TrlKeys.text]}
            {NON_BREAKING_SPACE}
            {MIDDLE_DOT}
            {currency && amount && (
                <>
                    {NON_BREAKING_SPACE}
                    <FormatMoney currency={currency}>{amount}</FormatMoney>
                </>
            )}
        </>
    );

    let renderItem;

    if (type === 'button') {
        renderItem = (
            <Button
                data-qa="pfp_billing_button"
                mode="secondary"
                size="small"
                onClick={handleBuyPfpContacts}
                loading={isLoading}
                stretched={isMobile}
            >
                {text}
            </Button>
        );
    } else if (type === 'resumeViewButton') {
        renderItem = (
            <Button
                data-qa="pfp_billing_button"
                mode="primary"
                size="small"
                style="accent"
                onClick={handleBuyPfpContacts}
                loading={isLoading}
                stretched={isMobile}
            >
                {text}
            </Button>
        );
    } else {
        renderItem = <MenuItem onClick={handleBuyPfpContacts}>{text}</MenuItem>;
    }

    return (
        <>
            {renderItem}
            {error && (
                <Error
                    resumeId={resumeId}
                    error={error}
                    onClose={onCloseError}
                    topicId={topicId}
                    vacancyId={vacancyId}
                />
            )}
        </>
    );
};

export default translation(PfpBillingButton);
