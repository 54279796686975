import { useCallback } from 'react';
import { createPortal } from 'react-dom';

import resumeCommentButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_comment_button_click';
import { Button as MagritteButton, Link } from '@hh.ru/magritte-ui';
import Button from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import CommentFormEdit from 'src/components/ResumeCard/Actions/Comments/Comment/FormEdit';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import Form from 'src/components/ResumeComments/Form';
import { AddProps } from 'src/components/ResumeComments/types';

const TrlKeys = {
    add: 'resume.comments.add',
    addInline: 'resume.comments.add.inline',
};

const Add: TranslatedComponent<AddProps> = ({
    trls,
    hide,
    user,
    onAdd,
    editId,
    setEditId,
    addFromCard,
    topicId,
    vacancyId,
    resumeHash,
    renderFormTo,
    inline = false,
}) => {
    const resumeId = useSelector((state) => state.resume?.id);
    const { addNotification } = useNotification();
    const isMagritte = useMagritte();

    const onClick = useCallback(() => {
        resumeCommentButtonClick({ vacancyId, resumeId });
        setEditId('add');
    }, [resumeId, setEditId, vacancyId]);

    if (hide) {
        return null;
    }

    if (editId === 'add') {
        const form = (
            <Form
                renderWrapper={addFromCard ? CommentFormEdit : undefined}
                user={user}
                onSubmitSuccessful={(response) => {
                    onAdd(response);
                    setEditId(null);
                    addNotification(resumeCommentAddMessage, { props: { message: response.body } });
                }}
                onCancel={() => setEditId(null)}
                topicId={topicId}
                vacancyId={vacancyId}
                resumeHash={resumeHash}
            />
        );
        if (inline && renderFormTo) {
            return createPortal(form, renderFormTo);
        }
        return form;
    }

    if (isMagritte) {
        return (
            <div className="resume-sidebar-noprint">
                {inline ? (
                    <Link
                        data-qa="add-comment"
                        mode="primary"
                        style="accent"
                        typography="label-3-regular"
                        onClick={onClick}
                    >
                        {trls[TrlKeys.addInline]}
                    </Link>
                ) : (
                    <MagritteButton
                        size="small"
                        mode="secondary"
                        style="neutral"
                        data-qa="add-comment"
                        stretched
                        onClick={onClick}
                    >
                        {trls[TrlKeys.add]}
                    </MagritteButton>
                )}
            </div>
        );
    }

    return (
        <div className="resume-sidebar-link">
            <BlokoLink Element={Button} onClick={onClick} data-qa="add-comment">
                {trls[TrlKeys.add]}
            </BlokoLink>
        </div>
    );
};

export default translation(Add);
