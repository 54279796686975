import { BannerShownData, BannerClickedData } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';
import fetcher from 'src/utils/fetcher';

const BANNER_SHOWN_URL = '/resumesearch/contact_feedback_banner/show';
const BANNER_ANSWERED_URL = '/resumesearch/contact_feedback_banner/answered';

declare global {
    interface FetcherPostApi {
        [BANNER_SHOWN_URL]: {
            queryParams: void;
            body: BannerShownData;
            response: void;
        };
    }
    interface FetcherPostApi {
        [BANNER_ANSWERED_URL]: {
            queryParams: void;
            body: BannerClickedData;
            response: void;
        };
    }
}

export const sendBannerShown = (data: BannerShownData, signal?: AbortSignal): void => {
    void fetcher.post(BANNER_SHOWN_URL, data, { signal }).catch((e) => {
        if (!fetcher.isCancel(e)) {
            console.error(e);
        }
    });
};

export const sendBannerAnswer = (data: BannerClickedData, signal?: AbortSignal): void => {
    void fetcher.post(BANNER_ANSWERED_URL, data, { signal }).catch((e) => {
        if (!fetcher.isCancel(e)) {
            console.error(e);
        }
    });
};
