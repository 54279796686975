import { memo, useCallback, useContext, useRef, useState } from 'react';

import responseNotComeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/responses/resume_card/response_not_come_button_click';
import {
    Action,
    Button,
    Modal,
    Card,
    Text as MagritteText,
    useBreakpoint,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    Cell,
    CellText,
    Radio,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import BlokoButton from 'bloko/blocks/button';
import Info, { InfoPlacement, InfoTheme } from 'bloko/blocks/drop/Info';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import Gap from 'bloko/blocks/gap';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import { ResumeCardActionsContext } from 'src/components/ResumeCard/Actions/Context';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import NegotiationTopic from 'src/models/negotiationTopic.types';

import NonAppearanceContent from 'src/components/Employer/NonAppearance/Content';
import useNonAppearance, { NonAppearanceReason } from 'src/components/Employer/NonAppearance/useNonAppearance';

const TrlKeys = {
    didNotAppear: 'vacancyResponse.didNotShowUp',
    didNotAppearMenu: 'vacancyResponse.didNotShowUp.menu',
    title: 'nonAppearanceModal.title',
    subtitle: 'nonAppearanceModal.subTitle',
    submit: 'nonAppearanceModal.submit',
    cancel: 'nonAppearanceModal.cancel',
    reasonRequired: 'nonAppearance.reasonRequired',
    reasons: {
        [NonAppearanceReason.ForceMajeure]: 'nonAppearanceReason.forceMajeure',
        [NonAppearanceReason.ChoseAnotherVacancy]: 'nonAppearanceReason.choseAnother',
        [NonAppearanceReason.Forgot]: 'nonAppearanceReason.forgot',
        [NonAppearanceReason.Other]: 'nonAppearanceReason.other',
    },
};

interface NonAppearanceProps {
    vacancyId: string;
    resumeHash: string;
    isMenuItem?: boolean;
    viewType: string;
    markAsRead?: () => void;
    closeMenu?: () => void;
    onSubmit?: () => void;
}

const NonAppearance: TranslatedComponent<NonAppearanceProps> = ({
    vacancyId,
    resumeHash,
    viewType,
    markAsRead,
    closeMenu,
    onSubmit,
    trls,
}) => {
    const {
        resume: { topicId },
        lastEmployerStateExtName,
    } = useContext(ResumeCardActionsContext);
    const { t } = useSelector((state) => state.router.location.query);
    const [showButton, setShowButton] = useState(true);
    const [isVisible, toggleIsVisible, setIsVisible] = useToggleState(false);
    const { isMobile } = useBreakpoint();
    const { addNotification } = useNotification();
    const infoActivatorRef = useRef(null);
    const isMagritte = useMagritte();
    const topic = topicId || t;

    const topicList: NegotiationTopic[] = useSelector((state) => state.employerNegotiations?.topicList) || [];

    const topicLastStateExtName =
        lastEmployerStateExtName ||
        topicList.find((negotiationTopic) => negotiationTopic.id.toString() === topic)?.lastEmployerStateExtName;

    const submitHandler = useCallback(() => {
        addNotification(resumeCommentAddMessage);
        markAsRead && markAsRead();
        setShowButton(false);
        closeMenu?.();
        onSubmit?.();
    }, [addNotification, closeMenu, markAsRead, onSubmit]);
    const handleClick = useCallback(() => {
        if (topicLastStateExtName && topic) {
            responseNotComeButtonClick({ vacancyId, topicId: topic, folderName: topicLastStateExtName });
        }
        toggleIsVisible();
    }, [topicLastStateExtName, topic, toggleIsVisible, vacancyId]);
    const onClose = useCallback(() => {
        setIsVisible(false);
        closeMenu?.();
    }, [closeMenu, setIsVisible]);

    const { reason, isValid, isLoading, onChangeReason, submitCallback, cancelCallback } = useNonAppearance({
        vacancyId,
        resumeHash,
        onCancel: onClose,
        onSubmit: submitHandler,
    });

    const magritteMenu = (
        <Card
            onClick={handleClick}
            borderRadius={16}
            padding={12}
            paddingLeft={16}
            hoverStyle="secondary"
            stretched
            data-qa="non-appearance__trigger"
            ref={infoActivatorRef}
        >
            <MagritteText style="primary" typography="label-2-regular">
                {trls[TrlKeys.didNotAppearMenu]}
            </MagritteText>
        </Card>
    );

    if (!showButton) {
        return null;
    }

    const renderTrigger = () => {
        switch (viewType) {
            case 'link':
                return (
                    <Gap top right>
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            onClick={handleClick}
                            data-qa="non-appearance__trigger"
                            ref={infoActivatorRef}
                        >
                            {trls[TrlKeys.didNotAppear]}
                        </Link>
                    </Gap>
                );
            case 'menu':
                return closeMenu || isMagritte ? (
                    magritteMenu
                ) : (
                    <MenuItem onClick={handleClick} data-qa="non-appearance__trigger" innerRef={infoActivatorRef}>
                        {trls[TrlKeys.didNotAppear]}
                    </MenuItem>
                );
            default:
                return isMagritte ? (
                    <Button
                        onClick={handleClick}
                        size="small"
                        mode="secondary"
                        style="accent"
                        data-qa="non-appearance__trigger"
                        ref={infoActivatorRef}
                    >
                        {trls[TrlKeys.didNotAppear]}
                    </Button>
                ) : (
                    <BlokoButton onClick={handleClick} data-qa="non-appearance__trigger" innerRef={infoActivatorRef}>
                        {trls[TrlKeys.didNotAppear]}
                    </BlokoButton>
                );
        }
    };

    const renderReasons = () => {
        return (
            <div role="radiogroup">
                {Object.values(NonAppearanceReason).map((value) => (
                    <Cell
                        key={value}
                        left={
                            <Radio
                                name="reason"
                                data-qa="non-appearance-form__reason"
                                onChange={() => {
                                    onChangeReason(value);
                                }}
                                invalid={!isValid}
                            />
                        }
                        vertPadding
                    >
                        <CellText>{trls[TrlKeys.reasons[value]]}</CellText>
                    </Cell>
                ))}
            </div>
        );
    };

    if (isMobile || closeMenu || isMagritte) {
        return (
            <>
                {isMobile ? (
                    <BottomSheet
                        data-qa="non-appearance-bottom-sheet"
                        header={
                            <NavigationBar
                                title={trls[TrlKeys.title]}
                                subtitle={trls[TrlKeys.subtitle]}
                                right={<Action icon={CrossOutlinedSize24} onClick={cancelCallback} />}
                                showDivider="always"
                            />
                        }
                        footer={
                            <BottomSheetFooter>
                                <Button
                                    data-qa="non-appearance-form__submit"
                                    mode="primary"
                                    style="accent"
                                    onClick={submitCallback}
                                    loading={isLoading}
                                    disabled={!isValid || !reason}
                                    stretched
                                >
                                    {trls[TrlKeys.submit]}
                                </Button>
                                <Button
                                    data-qa="non-appearance-form__close"
                                    mode="secondary"
                                    style="accent"
                                    onClick={cancelCallback}
                                    disabled={isLoading}
                                    stretched
                                >
                                    {trls[TrlKeys.cancel]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        visible={isVisible}
                        onClose={cancelCallback}
                    >
                        {renderReasons()}
                    </BottomSheet>
                ) : (
                    <Modal
                        data-qa="non-appearance-modal"
                        title={trls[TrlKeys.title]}
                        titleDescription={trls[TrlKeys.subtitle]}
                        footer={
                            <ActionBar
                                primaryActions={
                                    <Button
                                        data-qa="non-appearance-form__submit"
                                        mode="primary"
                                        style="accent"
                                        onClick={submitCallback}
                                        loading={isLoading}
                                        disabled={!isValid || !reason}
                                    >
                                        {trls[TrlKeys.submit]}
                                    </Button>
                                }
                                secondaryActions={
                                    <Button
                                        data-qa="non-appearance-form__close"
                                        mode="secondary"
                                        style="accent"
                                        onClick={cancelCallback}
                                        disabled={isLoading}
                                    >
                                        {trls[TrlKeys.cancel]}
                                    </Button>
                                }
                            />
                        }
                        size="small"
                        visible={isVisible}
                        onClose={cancelCallback}
                        actions={<Action mode="secondary" onClick={cancelCallback} icon={CrossOutlinedSize24} />}
                    >
                        {renderReasons()}
                    </Modal>
                )}
                {renderTrigger()}
            </>
        );
    }

    return (
        <Info
            theme={InfoTheme.Light}
            placement={viewType === 'link' ? InfoPlacement.TopStart : InfoPlacement.BottomEnd}
            show={isVisible}
            onClose={onClose}
            activatorRef={infoActivatorRef}
            render={() => (
                <NonAppearanceContent
                    vacancyId={vacancyId}
                    resumeHash={resumeHash}
                    onCancel={onClose}
                    onSubmit={submitHandler}
                />
            )}
        >
            {renderTrigger()}
        </Info>
    );
};

export default memo(translation(NonAppearance));
