import Button from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    resumeCommentAdd: 'resume.comments.notification.add.title',
    resumeCommentEdit: 'resume.comments.notification.edit.title',
    resumeCommentAdded: 'resume.comments.notification.add.title',
    resumeCommentDeleted: {
        deleted: 'employer.resume.comment.deleted',
        restore: 'employer.resume.comment.restore',
    },
};

const ResumeCommentEdit: TranslatedComponent<{ message: string }> = ({ trls, message }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.resumeCommentEdit]}</NotificationHeading>
        {message}
    </>
);
export const resumeCommentEditMessage = {
    Element: translation(ResumeCommentEdit),
    kind: 'ok',
    autoClose: true,
};

const ResumeCommentAddMessage: TranslatedComponent<{ message?: string }> = ({ trls, message }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.resumeCommentAdd]}</NotificationHeading>
        {message && message}
    </>
);
export const resumeCommentAddMessage = {
    Element: translation(ResumeCommentAddMessage),
    kind: 'ok',
    autoClose: true,
};

const ResumeCommentDeleteMessage: TranslatedComponent<{
    restoreComment: () => void;
    removeNotification: () => void;
}> = ({ trls, removeNotification, restoreComment }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.resumeCommentDeleted.deleted]}</NotificationHeading>
        <NotificationFooter>
            <Button
                data-qa="restore_comment_button"
                onClick={() => {
                    restoreComment();
                    removeNotification();
                }}
            >
                {trls[TrlKeys.resumeCommentDeleted.restore]}
            </Button>
        </NotificationFooter>
    </>
);

export const resumeCommentDeleteMessage = {
    Element: translation(ResumeCommentDeleteMessage),
    kind: 'ok',
    autoClose: true,
};
