import { useContext, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import responseNotPickUpButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/responses/resume_card/response_not_pick_up_button_click';
import { Button, Card, Text } from '@hh.ru/magritte-ui';
import BlokoButton from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import Gap from 'bloko/blocks/gap';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import applicantNoCallAnswer from 'src/components/Notifications/ApplicantNoCallAnswer';
import defaultError from 'src/components/Notifications/DefaultError';
import { useNotification } from 'src/components/Notifications/Provider';
import { ResumeCardActionsContext } from 'src/components/ResumeCard/Actions/Context';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import fetcher from 'src/utils/fetcher';

interface NoCallAnswerProps {
    topicId: string;
    viewType: 'link' | 'menu' | 'menuAdditional' | 'button';
    onClick?: () => void;
    markAsRead?: () => void;
}

const TrlKeys = {
    noCallAnswer: 'vacancyResponse.noCallAnswer',
    noCallAnswerMenu: 'vacancyResponse.noCallAnswer.menu',
};

const NO_CALL_ANSWER_COMPLAINT = '/shards/employer/no_call_answer_complaint';

interface BodyProps {
    topicId: string;
}

declare global {
    interface FetcherPostApi {
        [NO_CALL_ANSWER_COMPLAINT]: {
            queryParams: void;
            body: BodyProps;
            response: void;
        };
    }
}

const NoCallAnswer: TranslatedComponent<NoCallAnswerProps> = ({ trls, topicId, viewType, onClick, markAsRead }) => {
    const [isHandled, setIsHandled] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { addNotification } = useNotification();
    const { vacancyId, lastEmployerStateExtName } = useContext(ResumeCardActionsContext);
    const isMenuView = viewType === 'menu';
    const isMagritte = useMagritte();

    const handleClick = async () => {
        onClick?.();
        Analytics.sendHHEventButtonClick('applicant_is_unavailable', {
            topicId,
        });

        if (lastEmployerStateExtName) {
            responseNotPickUpButtonClick({ topicId, vacancyId, folderName: lastEmployerStateExtName });
        }
        try {
            setLoading(true);
            await fetcher.post(NO_CALL_ANSWER_COMPLAINT, { topicId });
            markAsRead?.();
            addNotification(applicantNoCallAnswer);
            if (!isMenuView) {
                setIsHandled(true);
            }
        } catch (_) {
            addNotification(defaultError);
        } finally {
            if (!isMenuView) {
                setLoading(false);
            }
        }
    };

    const magritteMenu = (
        <Card
            onClick={handleClick}
            borderRadius={16}
            padding={12}
            paddingLeft={16}
            hoverStyle="secondary"
            stretched
            data-qa="no-call-answer-menu"
        >
            <Text style="primary" typography="label-2-regular">
                {trls[TrlKeys.noCallAnswerMenu]}
            </Text>
        </Card>
    );

    if (isHandled) {
        return null;
    }

    switch (viewType) {
        case 'link':
            return (
                <Gap top right>
                    <Link appearance={isLoading ? LinkAppearance.Loading : LinkAppearance.Pseudo} onClick={handleClick}>
                        {trls[TrlKeys.noCallAnswer]}
                    </Link>
                </Gap>
            );
        case 'menu':
            return isMagritte ? magritteMenu : <MenuItem onClick={handleClick}>{trls[TrlKeys.noCallAnswer]}</MenuItem>;
        case 'menuAdditional':
            return magritteMenu;
        default:
            return isMagritte ? (
                <Button onClick={handleClick} size="small" mode="secondary" style="accent">
                    {trls[TrlKeys.noCallAnswer]}
                </Button>
            ) : (
                <BlokoButton onClick={handleClick}>{trls[TrlKeys.noCallAnswer]}</BlokoButton>
            );
    }
};

export default translation(NoCallAnswer);
