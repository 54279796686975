import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';
import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import { H2 } from 'bloko/blocks/header';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Error from 'bloko/blocks/modal/Error';
import ModalFooter from 'bloko/blocks/modal/ModalFooter';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { inviteToVideocall } from 'src/models/candidatesList';
import { EmployerStateType } from 'src/models/negotiationTopic.types';
import fetcher from 'src/utils/fetcher';

import videoInterviewSearchModalEnable from 'src/components/InviteToVideoCall/VideoInterviewSearchModal';
import { FormData, Applicant, VideoCallDuration } from 'src/components/InviteToVideoCall/types';

const POST_URL = '/employer/negotiations/change_topic';

interface ResponseErrorsData {
    errors: {
        key: string;
    }[];
}

type GenericServerError = AxiosError<ResponseErrorsData>;

const isGenericServerError = (error: unknown): error is GenericServerError =>
    axios.isAxiosError(error) && !!(error as GenericServerError).response?.data?.errors;

interface RenderMailText {
    <IP extends boolean>(isPreview: IP): IP extends true ? JSX.Element : string;

    (isPreview: boolean): JSX.Element | string;
}

const TrlKeys = {
    invitation: 'resume.online.interview.preview.invitation',
    theme: 'resume.online.interview.theme',
    greeting: 'resume.online.interview.preview.greeting',
    questions: 'resume.online.interview.preview.questions',
    respect: 'resume.online.interview.preview.respect',
    link: 'resume.online.interview.preview.link',
    min: 'resume.online.interview.invite.min',
    hour: 'hour.1',
    hours: 'hour.2',
    send: 'resume.online.interview.preview.send',
    back: 'resume.online.interview.preview.back',
    title: 'resume.online.interview.preview.title',
    step: 'resume.online.interview.invite.step.two',
    errorDefaut: 'resume.online.interview.preview.error',
    errorMore5: 'negotiations.changeTopic.errors.INBOX_MORE_5',
    errorMore100: 'negotiations.changeTopic.errors.INBOX_MORE_100',
};

interface InvitetionProps {
    prevStep: () => void;
    resumeStateUpdate: (interview: EmployerStateType, time: string) => void;
    applicant: Applicant;
    topicId?: string;
    formData: FormData;
    onClose: () => void;
}

const Invitation: TranslatedComponent<InvitetionProps> = ({
    prevStep,
    applicant,
    formData,
    onClose,
    resumeStateUpdate,
    topicId,
    trls,
}) => {
    const isEmployerSubstatesExp = useSelector((state) => state.isEmployerSubstatesExp);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const employerManager = useSelector((state) => state.employerManager);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const errorText = useMemo(() => {
        if (error) {
            if (error === 'INBOX_MORE_5') {
                return trls[TrlKeys.errorMore5];
            }
            if (error === 'INBOX_MORE_100') {
                return trls[TrlKeys.errorMore100];
            }
            return trls[TrlKeys.errorDefaut];
        }
        return '';
    }, [error, trls]);

    if (!employerManager) {
        return null;
    }

    const renderMailText: RenderMailText = (isPreview: boolean) => {
        const durationTexts = {
            [VideoCallDuration.QuaterAnHour]: `15 ${trls[TrlKeys.min]}`,
            [VideoCallDuration.HalfAnHour]: `30 ${trls[TrlKeys.min]}`,
            [VideoCallDuration.ThreeQuatersAnHour]: `45 ${trls[TrlKeys.min]}`,
            [VideoCallDuration.Hour]: `1 ${trls[TrlKeys.hour]}`,
            [VideoCallDuration.HourAndAHalf]: '1:30',
            [VideoCallDuration.TwoHours]: `2 ${trls[TrlKeys.hours]}`,
        };

        const greeting = applicant.name
            ? `${trls[TrlKeys.greeting]}, ${applicant.name}!`
            : `${trls[TrlKeys.greeting]}!`;

        const date = parse(formData.date, 'yyyy-MM-dd', new Date());
        const timezoneOffset = formatDate(date, 'xxx');
        const timezoneName = /\((.*)\)$/.exec(String(date))?.[1];

        const invitation = format(trls[TrlKeys.invitation], {
            '{date}': formatDate(formData.date, 'd MMMM'),
            '{timezone}': timezoneName ? `${timezoneOffset} ${timezoneName}` : timezoneOffset,
            '{time}': formData.time,
            '{duration}': durationTexts[formData.duration],
        });

        const respect = format(trls[TrlKeys.respect], {
            '{0}': `${employerManager.lastName} ${employerManager.firstName}`,
        });

        if (isPreview) {
            const lineBreak = <br />;
            const comment = formData.comment ? (
                <>
                    {formData.comment}
                    {lineBreak}
                    {lineBreak}
                </>
            ) : (
                ''
            );
            return (
                <>
                    {greeting}
                    {lineBreak}
                    {lineBreak}
                    <span suppressHydrationWarning>{invitation}</span>
                    {lineBreak}
                    {lineBreak}
                    <span className="resume-online-interview-link">{trls[TrlKeys.link]}</span>
                    {lineBreak}
                    {lineBreak}
                    {comment}
                    {trls[TrlKeys.questions]}
                    {lineBreak}
                    {respect}
                </>
            );
        }
        const lineBreak = '\n';
        const comment = formData.comment ? `${formData.comment}${lineBreak}${lineBreak}` : ``;

        return `${greeting}${lineBreak}${lineBreak}`
            .concat(`${invitation}${lineBreak}${lineBreak}`)
            .concat(`${trls[TrlKeys.link]}: [VideoCallUrl]`)
            .concat(`${lineBreak}${lineBreak}`)
            .concat(comment)
            .concat(`${trls[TrlKeys.questions]}${lineBreak}`)
            .concat(respect);
    };

    const onSubmit = async () => {
        setLoading(true);
        setError('');

        let fullName = '';
        if (applicant.name && applicant.lastName) {
            fullName = `${applicant.lastName} ${applicant.name}`;
        }

        const videoCallName = fullName
            ? `${trls[TrlKeys.theme]} "${formData.vacancyName}" — ${fullName}`
            : `${trls[TrlKeys.theme]} "${formData.vacancyName}"`;

        const callTime = formatISO(parse(`${formData.date} ${formData.time}`, 'yyyy-MM-dd HH:mm', new Date()));

        try {
            await fetcher.postFormData(POST_URL, {
                state: EmployerStateType.Interview.toLowerCase(),
                vacancyId: formData.vacancyId,
                resumeHash: applicant.resumeHash,
                mailText: renderMailText(false),
                videoCallStartTime: callTime,
                videoCallDurationInMinutes: formData.duration,
                videoCallName,
            });
        } catch (error) {
            if (isGenericServerError(error)) {
                setError(error.response?.data.errors[0]?.key || 'default');
            } else {
                setError('default');
            }
            Analytics.sendHHEventButtonClick('video_call_search_invite_error');
            setLoading(false);
            return;
        }
        addNotification(videoInterviewSearchModalEnable, { props: { fullName } });
        if (resumeStateUpdate) {
            resumeStateUpdate(EmployerStateType.Interview, callTime);
        }
        if (topicId) {
            dispatch(inviteToVideocall({ topicId, isSubstatesExp: isEmployerSubstatesExp }));
        }

        Analytics.sendHHEventButtonClick('video_call_search_invite_success');
        Analytics.sendEvent('employer', 'invitation', 'videocall');
        onClose();
    };

    return (
        <div className="resume-online-interview">
            <div className="resume-online-interview-head">
                <H2>{trls[TrlKeys.title]}</H2>
            </div>
            {renderMailText(true)}
            <Error visible={Boolean(error)}>{errorText}</Error>
            <ModalFooter>
                <Button appearance={ButtonAppearance.Outlined} onClick={prevStep} data-qa="invite-video-call-back">
                    {trls[TrlKeys.back]}
                </Button>
                <FormSpacer>
                    <Button
                        kind={ButtonKind.Primary}
                        onClick={onSubmit}
                        disabled={loading}
                        loading={loading && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                        data-qa="invite-video-call-submit"
                    >
                        {trls[TrlKeys.send]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </div>
    );
};

export default translation(Invitation);
